/* Google Font CDN Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

body {
  min-height: 100vh;
  width: 90%;
  background: #2d3242;
  display: flex;
  text-align: right;
}

.form-container {
  margin-top: 25px;
  margin-left: 100px;
  /* Adjust for mobile */
  width: 95%;
  /* Adjust for mobile */
  background: #fff;
  border-radius: 6px;
  padding: 20px 10px 30px 10px;
  /* Adjust for mobile */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.form-container .form-content .topic-text {
  font-size: 23px;
  text-align: center;
  font-weight: 600;
  color: #3e2093;
}

.form-container .form-content .input-box {
  display: block;
  /* Change to block for mobile */
  margin: 15px auto;
  /* Adjust for mobile */
  /* Adjust for mobile */
  height: 50px;
  position: relative;
  align-items: center;
  text-align: right;
}

.form-container .form-content .input-box input,
.form-container .form-content .input-box textarea {
  height: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #f0f1f8;
  border-radius: 6px;
  text-align: center;
  resize: none;
}

.form-container .form-content .label {
  font-weight: bold;
  font-size: smaller;
  text-align: right;
  margin: 1px;
}

.form-container .form-content .input-box textarea {
  padding-top: 6px;
}

.form-container .form-content .button {
  display: block;
  /* Change to block for mobile */
  margin: 12px auto;
  /* Adjust for mobile */
}

.form-container .form-content .button input[type="submit"] {
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #3e2093;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button input[type="button"] {
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #3e2093;
  cursor: pointer;
  transition: all 0.3s ease;
}

.form-container .form-content .button input[type="submit"]:hover {
  background: #5029bc;
}

.form-container .form-content {
  margin-left: 0;
}

.grid-container-element {
  display: grid;
  grid-template-columns: 1fr;
  background: rgba(114, 186, 94, 0.05);
  border: 2px dashed rgba(114, 186, 94, 0.35);
}

@media (min-width: 768px) {

  /* Adjust breakpoint as needed */
  .form-container {
    align-items: center;
    margin-left: 10%;
    width: 79%;
    padding: 20px 10px 30px 10px;
  }

  .form-container .form-content .input-box {
    display: inline-block;
   margin-right: 50px;
    
  }

  .form-container .form-content .button {
    display: inline-block;
    margin-top: 12px;
  }

  .grid-container-element {
    grid-template-columns: 1fr 1fr 1fr;
  }

}

.grid-about-container-element {
  display: grid;
  text-align: center;
  margin-left: 1px;
  margin-right: 10px;
  grid-template-columns: 1fr 1fr 1fr;
}
.button-container {
  display: flex;
  justify-content: center;
  margin: 50px;
  margin-bottom: 10px;
  /* Add some bottom margin for spacing */
}

.grid-about-container-element .name-text {
  font-size: 45px;
  text-align: center;
  font-weight: 600;
  color: #13acd6;
}

.about-label {
  color: lightgray;
  font-size: 17px;
  /* Adjust the font size as needed */
  font-weight: bold;
  /* You can adjust the font weight if desired */
}

.about-text {
  color: goldenrod;
  text-align: center;
  font-size: 20px;
  /* Adjust the font size as needed */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Styles for the modal */
.modalDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalDialog>div {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.currency-wrap {
  position: relative;
}

.currency-code {
  position: absolute;
  left: 8px;
  top: 10px;
}

.text-currency {
  border: solid 1px #ccc;
  border-radius: 5px;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  color: #666;
  /* Icon color */
  font-size: 1.2rem;
  /* Icon size */
}