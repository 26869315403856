
  .footer {
    padding: 40px 0;
   
  }

  .footer .social a:hover {
    opacity: 0.9;
  }
  .footer ul {
    margin-top: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 0;
  }
  .footer ul a {
    color: gainsboro;
    text-decoration: none;
    opacity: 0.8;
  }
  .footer ul li {
    display: inline-block;
    padding: 0 15px;
  }
  .footer ul a:hover {
    opacity: 1;
  }
  .footer .copyright {
    margin-top: 15px;
    text-align: center;
    font-size: 13px;
    color: #aaa;
  }
