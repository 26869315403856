.header_container {
    display: flex;
    justify-content: space-between;

  }
  .left-item {
    align-self: flex-start; /* Align to the left */
    color: lightgray
  }
  
  .right-item {
    margin-right: 25px;
    align-self: flex-end; /* Align to the right */
  }