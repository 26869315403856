body {
  font-family: Arial, sans-serif;
  background-color: white;
  margin: 50px;
}

h1 {
  color: "white"
}

p {
  text-align: center;
}

h3 {
  font-size: 100px;
}


.slideshow-container {
  position: relative;
  max-width: 100%;
  height: 200px;
  margin: 0 auto;
}

.fade {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slideshow-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fade.active {
  opacity: 1;
}

.mainContent {
  display: inline-block;
  align-content: center;
  padding: 1rem;
  
}

.mainContent .about-info {
margin-top: 40px;
}

