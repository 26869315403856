nav {
    margin: 25px;
    position: relative;
    width: 350px;
    height: 50px;
    background-color: #34495e;
    border-radius: 8px;
    font-size: 0;
  }
  nav a {
    line-height: 50px;
    height: 100%;
    font-size: 15px;
    display: inline-block;
    position: relative;
    z-index: 1;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    color: rgb(255, 255, 255);
    cursor: pointer;
  }
  nav .animation {
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 0;
    transition: all .3s ease 0s;
    border-radius: 8px;
  }
  a:nth-child(1) {
    width: 100px;
  }
  a:nth-child(2) {
    width: 110px;
  }
  a:nth-child(3) {
    width: 100px;
  }
  nav .start-home, a:nth-child(1):hover~.animation {
    width: 120px;
    left: 0;
    background-color: #3a9784;
  }
  nav .start-about, a:nth-child(2):hover~.animation {
    width: 110px;
    left: 120px;
    background-color: red;
  }
  nav .start-blog, a:nth-child(3):hover~.animation {
    width: 120px;
    left: 220px;
    background-color: #3498db;
  }
  