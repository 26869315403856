div.radio-wrapper {
    display: inline-block;
    text-align: right;
    font-size: 0.75em;
    font-weight: bolder;
    color: blue;
    margin-right: 40px;
  }
  
  div.radio-container {
    background-color: #3e2093;
    display: inline-block;
    border: 2px solid #3e2093;
  }
  
  div.radio-container input[type=radio] {
    opacity: 0;
    display: none;
    margin: 0;
    outline: none;
  }
  
  div.radio-container input[type="radio"]:checked+label {
    background-color: #3e2093;
    color: white;
    animation-name: radio-active;
    animation-duration: 0.2s;
  }
  
  @keyframes radio-active {
    from {
        transform: scale(0.6, 0.6);
    }
    to {
        transform: scale(1, 1);
    }
  }
  
  div.radio-container label {
    padding: 0.8em;
    cursor: pointer;
    background: none;
    display: inline-block;
    outline: none;
    letter-spacing: 0.1em;
    background-color: white;
    color: black;
    transition: all ease-in-out 0.1s;
  }
  
  